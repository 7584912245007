<template>
  <div>
    <ContentTable />
  </div>
</template>

<script>
import ContentTable from '@/components/Admin/Content/ContentTable'

export default {
  name: 'ContentDetail',
  components: { ContentTable },
}
</script>

<style scoped></style>
