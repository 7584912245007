<template>
  <div id="ContentTable">
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-uppercase display-2 font-weight-black"
        >
          Content Page
          <v-col>
            <v-btn color="primary" class="text-uppercase" @click="addnew">
              Create Main Content
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <BaseLoading :loading="loading" />
      <v-dialog v-model="conetnetListDialog" persistent>
        <BaseLoading :loading="loading" />
        <v-card>
          <v-card-title>
            <span class="headline">{{ activeItem }}</span>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="formContentList"
              v-model="validContentList"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="8">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Body TH
                    </div>
                    <v-textarea
                      v-model="content_list.body_th"
                      label="Body TH"
                      hint="Hint text"
                      rows="2"
                      :rules="textAreaRules"
                      append-icon="mdi-comment"
                      required
                      counter
                      maxLength="500"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Body Eng
                    </div>
                    <v-textarea
                      v-model="content_list.body_en"
                      label="Body TH"
                      hint="Hint text"
                      rows="2"
                      :rules="textAreaRules"
                      append-icon="mdi-comment"
                      required
                      counter
                      maxLength="500"
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title TH
                    </div>
                    <v-text-field
                      v-model="content_list.title_th"
                      label="Title TH"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title Eng
                    </div>
                    <v-text-field
                      v-model="content_list.title_en"
                      label="Title TH"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="create()"
              :disabled="!validContentList"
              v-if="this.modal == 'new'"
            >
              Save
            </v-btn>
            <v-btn color="blue darken-1" text @click="closeContentList"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog1" persistent>
        <BaseLoading :loading="loading" />
        <v-card>
          <v-card-title>
            <span class="headline">{{ activeItem }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="8">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">Body TH</div>
                    <ckeditor
                      v-model="content_detail.body_th"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">Body Eng</div>
                    <ckeditor
                      v-model="content_detail.body_en"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-capitalize headline mb-5">
                        Video or Long Page Image
                      </div>
                      <v-divider light></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-radio-group v-model="radioGroup" row>
                        <v-radio
                          v-for="(item, i) in listType"
                          :key="i"
                          :label="item.title"
                          :value="item.value"
                          @change="switchType(item.value)"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="4" v-if="radioGroup === 1">
                      <v-text-field
                        v-model="content_detail.video_url"
                        :label="`https://vimeo.com/idVideo`"
                        outlined
                        maxLength="50"
                        counter
                        @change="getVimeo()"
                      ></v-text-field>
                      <v-text-field
                        v-model="content_detail.seller_sku"
                        label="Seller SKU"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="radioGroup === 1">
                      <BaseVimeo
                        v-if="content_detail.video_url !== ''"
                        :key="componentKey"
                        cssVideo="video"
                        :pathVideo="content_detail.video_url"
                      ></BaseVimeo>
                    </v-col>
                    <v-col cols="4" v-if="radioGroup === 2">
                      <v-file-input
                        v-model="pdf_files"
                        color="deep-purple accent-4"
                        counter
                        accept="image/png, image/jpeg, image/bmp"
                        label="File input (Image Long Page)"
                        @change="uploadPDFImages"
                        placeholder="Select your files"
                        prepend-icon="mdi-camera"
                        :show-size="1000"
                        :rules="fileSizeRules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }}
                            File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Image Title
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <!--         file for edit         -->
                    <v-file-input
                      v-if="this.modal == 'edit'"
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                      :rules="fileSizeRules"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for edit         -->

                    <!--         file for craete         -->
                    <v-file-input
                      v-if="this.modal == 'new'"
                      :rules="fileRulesNews"
                      required
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for craete         -->

                    <!--         file for add         -->
                    <v-file-input
                      v-if="this.modal == 'add'"
                      :rules="fileRulesNews"
                      required
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for add         -->
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title TH
                    </div>
                    <v-text-field
                      v-model="content_detail.title_th"
                      label="Title TH"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title Eng
                    </div>
                    <v-text-field
                      v-model="content_detail.title_en"
                      label="Title EN"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>

                  <!--                  <v-col cols="12" v-if="this.modal == 'edit'">-->
                  <!--                    <div class="text-capitalize headline mb-5">-->
                  <!--                      <span class="red&#45;&#45;text">*</span>-->
                  <!--                      Sort By-->
                  <!--                    </div>-->
                  <!--                    <v-divider light></v-divider>-->
                  <!--                  </v-col>-->
                  <!--                  <v-col cols="12" v-if="this.modal == 'edit'">-->
                  <!--                    <v-text-field-->
                  <!--                      label="Sort By"-->
                  <!--                      outlined-->
                  <!--                      min="1"-->
                  <!--                      max="999"-->
                  <!--                      type="number"-->
                  <!--                      :rules="sortRules"-->
                  <!--                      v-model="content_detail.sort"-->
                  <!--                      required-->
                  <!--                      counter-->
                  <!--                      maxlength="3"-->
                  <!--                    ></v-text-field>-->
                  <!--                  </v-col>-->

                  <v-col
                    cols="12"
                    v-if="this.modal !== 'add' && content_detail.parent_id == 0"
                  >
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Footer Menu
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="this.modal !== 'add' && content_detail.parent_id == 0"
                  >
                    <v-select
                      :items="footer_menu || footerId"
                      label="Footer Menu"
                      item-value="id"
                      item-text="name"
                      outlined
                      :rules="[v => !!v || 'Required']"
                      required
                      v-model="content_detail.footer_menu_id"
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      Create Sub Content
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="content_detail.bullet_title_th"
                      label="Sub Content TH"
                      maxLength="50"
                      counter
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="content_detail.bullet_title_en"
                      label="Sub Content Eng"
                      maxLength="50"
                      counter
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="content_detail.sort"
                      label="Sort"
                      type="number"
                      outlined
                    ></v-text-field>
                    <v-radio-group v-model="visible_selected">
                      <v-radio
                        v-for="(item, index) in visible_choices"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></v-radio>
                    </v-radio-group>
                    <v-checkbox
                      class="pt-1 mt-0"
                      v-model="content_detail.is_pin"
                      label="Pin this content"
                    >
                    </v-checkbox>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="saveData()"
              :disabled="!valid"
              v-if="this.modal == 'new' || this.modal == 'add'"
            >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="update"
              :disabled="!valid"
              v-if="this.modal == 'edit'"
            >
              Save Edit
            </v-btn>
            <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="text-capitalize display-1 my-5">Content</div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        label="Search"
        hide-details
      ></v-text-field>

      <div class="d-flex ma-4">
        <div class="box-parent mx-2"></div>
        #Parent Content
        <div class="box-child mx-2"></div>
        #Sub Content
      </div>

      <v-data-table
        height="500"
        dark
        fixed-header
        :headers="headers"
        :items="content_details"
        :items-per-page="pageSize"
        :page.sync="page"
        :search="search"
        :sort-desc="['id']"
        hide-default-footer
        class="elevation-1 my-5"
        :item-class="itemRowBackground"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.subs="{ item }">
          <v-icon @click="addParent(item)">mdi-plus</v-icon>
        </template>
        <template v-slot:item.lists="{ item }">
          <v-icon @click="addContentList(item)">mdi-plus</v-icon>
        </template>
        <template v-slot:item.jp_and_above="{ item }">
          {{ isJpAndAbove(item) }}
        </template>
        <template v-slot:item.is_visible_ep="{ item }">
          {{ isEpOnly(item) }}
        </template>
        <template v-slot:item.is_pin="{ item }">
          {{ item.is_pin ? 'Yes' : 'No' }}
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="next"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import ConntentsServices from '@/services/ConntentsServices'
import { FormMixin } from '@/Mixins/FormMixin'
import FooterMenuServices from '@/services/FooterMenuServices'
import VISIBLE_CHOICES, { VISIBLE_VALUES } from '@/constants/visibleChoices'
import axios from 'axios'

export default {
  mixins: [FormMixin],
  name: 'ContentTable',
  data() {
    return {
      headers: [
        { text: 'ID', align: 'start', value: 'id', width: '1%' },
        {
          text: 'Title_TH',
          align: 'start',
          value: 'title_th',
          width: '10%',
        },
        {
          text: 'Title_EN',
          align: 'start',
          value: 'title_en',
          width: '10%',
        },
        {
          text: 'Bullet_title_th',
          value: 'bullet_title_th',
          align: 'start',
          width: '10%',
        },
        {
          text: 'Bullet_title_en',
          value: 'bullet_title_en',
          align: 'start',
          width: '10%',
        },
        {
          text: 'Video_url',
          value: 'video_url',
          align: 'start',
          width: '10%',
        },
        { text: 'Sort', value: 'sort', align: 'start', width: '1%' },
        {
          text: 'Footer_menu_id',
          value: 'footer_menu_id',
          align: 'start',
          width: '1%',
        },
        {
          text: 'Parent_id',
          value: 'parent_id',
          align: 'start',
          width: '1%',
          class: 'warning--text',
        },
        {
          text: 'Sub content list',
          value: 'lists',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Topic in sub content',
          value: 'subs',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Pacer Only',
          value: 'jp_and_above',
          align: 'center',
          width: '10%',
        },
        {
          text: 'For EP only',
          value: 'is_visible_ep',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Pin Content',
          value: 'is_pin',
          align: 'center',
          width: '10%',
        },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
      footer_menu: [],
      visible_choices: VISIBLE_CHOICES,
      visible_selected: VISIBLE_VALUES.ALL,
      content_detail: {
        title_th: '',
        title_en: '',
        body_th: '',
        body_en: '',
        bullet_title_th: '',
        bullet_title_en: '',
        video_url: '',
        parent_id: 0,
        footer_menu_id: null,
        sort: null,
        is_visible_ep: false,
        is_visible_sp: false,
        is_visible_jp: false,
        is_pin: false,
        duration: 0,
        seller_sku: '',
      },
    }
  },
  created() {
    this.readData()
  },
  methods: {
    getVimeo() {
      try {
        let link = this.content_detail.video_url
        const checkLink2 = link.split('https://vimeo.com/')
        if (checkLink2.length > 1) {
          const linkSub = checkLink2[1]
          const resultSub = linkSub.split('/')
          if (resultSub.length > 1) {
            this.getDuration(
              'https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/' +
                resultSub[0] +
                '?h=' +
                resultSub[1],
            )
          } else {
            this.getDuration(
              'https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/' +
                checkLink2[1],
            )
          }
        }
      } catch (error) {
        console.log('link Not Work')
      }
    },
    async getDuration(link) {
      let duration = await axios.get(link)
      this.content_detail.duration = duration.data.duration
    },
    next() {
      this.readData()
    },
    readData() {
      ConntentsServices.getContent({
        page: this.page,
        limit: this.pageSize,
      })
        .then(res => {
          if (res.status === 200) {
            this.content_details = []
            this.content_details = res.data.items
            this.totalPages = res.data.meta.totalPages
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })

      FooterMenuServices.getFooterMenus()
        .then(resp => {
          if (resp.status === 200) {
            for (var i = 0; i <= resp.data.length; i++) {
              if (i === 3) {
                break
              }

              this.footer_menu.push({
                id: resp.data[i].id,
                name: resp.data[i].title_th.replace(/<[^>]+>/g, ''),
              })
            }
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    updateIsNew(e) {
      this.product.is_new = e
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        let formData = new FormData()
        formData.append('image', this.file)
        formData.append('contentImage', this.pdf_file)
        formData.append('titleTH', this.content_detail.title_th)
        formData.append('titleEN', this.content_detail.title_en)
        formData.append('bodyTH', this.content_detail.body_th)
        formData.append('bodyEN', this.content_detail.body_en)
        formData.append('bulletTitleTH', this.content_detail.bullet_title_th)
        formData.append('bulletTitleEN', this.content_detail.bullet_title_en)
        formData.append('parentId', this.content_detail.parent_id)
        formData.append('footerMenuId', this.content_detail.footer_menu_id)
        formData.append('videoUrl', this.content_detail.video_url)
        formData.append('isPin', this.content_detail.is_pin)
        formData.append('sort', Number(this.content_detail.sort))
        formData.append('duration', Number(this.content_detail.duration))
        formData.append('sellerSku', this.content_detail.seller_sku)

        if (this.visible_selected === VISIBLE_VALUES.JP_AND_ABOVE) {
          formData.append('isVisibleJpAndAbove', true)
        } else if (this.visible_selected === VISIBLE_VALUES.EP_ONLY) {
          formData.append('isVisibleEp', true)
        }

        ConntentsServices.createContent(formData)
          .then(() => {
            this.$swal.fire({
              icon: 'success',
              title: 'Content created successfully',
            })
            this.loading = !this.loading
            this.dialog1 = false
            this.resetData()
            this.readData()
          })
          .catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: `${err.response.data.message}`,
            })
            this.loading = !this.loading
            if (err.response.status === 401) {
              this.removeToken()
              this.$router.push({ name: 'Admin' })
            }
          })
      }
    },
    create() {
      if (this.$refs.formContentList.validate()) {
        this.loading = !this.loading
        ConntentsServices.createContent_List({
          titleTH: this.content_list.title_th,
          titleEN: this.content_list.title_en,
          bodyTH: this.content_list.body_th,
          bodyEN: this.content_list.body_en,
          contentId: parseInt(this.content_list.content_id),
        })
          .then(() => {
            this.$swal.fire({
              icon: 'success',
              title: 'Content List created successfully',
            })
            this.conetnetListDialog = false
            this.loading = !this.loading
            this.resetContentList()
            this.readData()
          })
          .catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: `${err.response.data.message}`,
            })
            this.loading = !this.loading
            if (err.response.status === 401) {
              this.removeToken()
              this.$router.push({ name: 'Admin' })
            }
          })
      }
    },
    resetData() {
      this.content_detail = {
        title_th: '',
        title_en: '',
        body_th: '',
        body_en: '',
        bullet_title_th: '',
        bullet_title_en: '',
        video_url: '',
        parent_id: 0,
        footer_menu_id: null,
        sort: null,
        is_visible_ep: false,
        is_visible_sp: false,
        is_visible_jp: false,
        is_pin: false,
      }
      this.fileSingle = null
      this.file = ''
      this.pdf_files = null
      this.pdf_file = ''
      this.visible_selected = VISIBLE_VALUES.ALL
    },
    resetContentList() {
      this.content_list = {
        title_th: '',
        title_en: '',
        body_th: '',
        body_en: '',
        content_id: null,
      }
    },
    addnew() {
      this.modal = 'new'
      this.activeItem = 'New Content'
      this.resetData()
      this.dialog1 = true
    },
    editItem(contentDetail) {
      this.modal = 'edit'
      this.activeItem = 'Edit Content'
      this.content_detail = contentDetail
      this.dialog1 = true
      this.handleVisibleChoices(contentDetail)
    },
    addParent(contentDetail) {
      this.modal = 'add'
      this.activeItem = 'Add topic in sub content'
      this.content_detail = {
        ...this.content_detail,
        ...{
          parent_id: contentDetail.id,
          footer_menu_id: contentDetail.footer_menu_id,
        },
      }
      this.dialog1 = true
    },
    addContentList(contentDetail) {
      this.modal = 'new'
      this.activeItem = 'New Content'
      this.content_list = {
        ...this.content_list,
        ...{
          content_id: contentDetail.id,
        },
      }
      this.conetnetListDialog = true
    },
    deleteItem(doc) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            ConntentsServices.deleteContent(doc.id)
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Content delete in successfully',
                })
                this.loading = !this.loading
                this.readData()
                this.resetData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    update() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, edit it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            let formData = new FormData()
            formData.append('image', this.file)
            formData.append('contentImage', this.pdf_file)
            formData.append('titleTH', this.content_detail.title_th)
            formData.append('titleEN', this.content_detail.title_en)
            formData.append('bodyTH', this.content_detail.body_th)
            formData.append('bodyEN', this.content_detail.body_en)
            formData.append(
              'bulletTitleTH',
              this.content_detail.bullet_title_th,
            )
            formData.append(
              'bulletTitleEN',
              this.content_detail.bullet_title_en,
            )
            formData.append('parentId', this.content_detail.parent_id)
            formData.append('footerMenuId', this.content_detail.footer_menu_id)
            formData.append('videoUrl', this.content_detail.video_url)
            formData.append('sort', Number(this.content_detail.sort))
            formData.append('isPin', this.content_detail.is_pin)

            if (this.visible_selected === VISIBLE_VALUES.JP_AND_ABOVE) {
              formData.append('isVisibleJpAndAbove', true)
            } else if (this.visible_selected === VISIBLE_VALUES.EP_ONLY) {
              formData.append('isVisibleEp', true)
            } else {
              formData.append('isVisibleEp', false)
              formData.append('isVisibleSp', false)
              formData.append('isVisibleJp', false)
            }
            formData.append('duration', this.content_detail.duration)
            formData.append('sellerSku', this.content_detail.seller_sku)

            ConntentsServices.updateContent(this.content_detail.id, formData)
              .then(() => {
                this.dialog1 = false
                this.$swal.fire({
                  icon: 'success',
                  title: 'Content edited successfully',
                })
                this.loading = !this.loading
                this.readData()
                this.resetData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    itemRowBackground(item) {
      return item.parent_id !== 0 ? 'subContent' : 'parentContent'
    },
    handleVisibleChoices(contentDetail) {
      if (
        contentDetail.is_visible_jp &&
        contentDetail.is_visible_sp &&
        contentDetail.is_visible_ep
      ) {
        this.visible_selected = VISIBLE_VALUES.JP_AND_ABOVE
      } else if (contentDetail.is_visible_ep) {
        this.visible_selected = VISIBLE_VALUES.EP_ONLY
      } else {
        this.visible_selected = VISIBLE_VALUES.ALL
      }
    },
    isJpAndAbove(item) {
      return item.is_visible_ep && item.is_visible_sp && item.is_visible_jp
    },
    isEpOnly(item) {
      return item.is_visible_ep && !item.is_visible_sp && !item.is_visible_jp
    },
  },
}
</script>

<style>
#ContentTable .subContent {
  background-color: #444444;
}

#ContentTable .box-parent {
  width: 30px;
  border-radius: 10px;
  background-color: #1e1e1e;
}

#ContentTable .box-child {
  width: 30px;
  border-radius: 10px;
  background-color: #444444;
}
</style>
