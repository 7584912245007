import { apiServices } from '@/utils/request'

export default {
  getContents(id) {
    return apiServices.get('/contents/' + id)
  },

  getContent({ page, limit }) {
    return apiServices.get(`/contents?page=${page}&limit=${limit}`)
  },

  createContent(formData) {
    return apiServices.post('/contents', formData)
  },

  updateContent(id, formData) {
    return apiServices.put(`/contents/${id}`, formData)
  },

  deleteContent(id) {
    return apiServices.delete(`/contents/${id}`)
  },

  getContentsByFooterId({ id, page, limit }) {
    return apiServices.post(
      `/contents/getContentByFooterId?footer_id=${id}&page=${page}&limit=${limit}`,
    )
  },

  getContentsByParentId({ id, page, limit }) {
    return apiServices.post(
      `/contents/getConentByParentId?parent_id=${id}&page=${page}&limit=${limit}`,
    )
  },

  getContent_Lists(id) {
    return apiServices.get('/content-lists/' + id)
  },

  getContent_List() {
    return apiServices.get('/content-lists/')
  },

  createContent_List({ titleTH, titleEN, bodyTH, bodyEN, contentId }) {
    return apiServices.post('content-lists', {
      titleTH,
      titleEN,
      bodyTH,
      bodyEN,
      contentId,
    })
  },

  deleteContent_List(id) {
    return apiServices.delete(`/content-lists/${id}`)
  },

  updateContent_List(id, { titleTH, titleEN, bodyTH, bodyEN, contentId }) {
    return apiServices.put(`/content-lists/${id}`, {
      titleTH,
      titleEN,
      bodyTH,
      bodyEN,
      contentId,
    })
  },
}
